/* eslint-disable @typescript-eslint/no-unused-vars */
import { EventScoreDto, EventType } from '@/models/Event';
import { SerieName } from '@/models/Statistics';
import moment from 'moment';
import dateFormat from './DateFormat.helper';

class ChartHelper {
  imgFeeling: HTMLImageElement;
  imgMeal: HTMLImageElement;
  imgRest: HTMLImageElement;
  imgTrainning: HTMLImageElement;
  imgCustom: HTMLImageElement;
  imgRestEnd: HTMLImageElement;
  imgTrainningEnd: HTMLImageElement;

  constructor() {
    this.imgFeeling = new Image(32, 32);
    this.imgFeeling.src = `${window.location.origin}/assets/images/chart/feeling.svg`;
    this.imgMeal = new Image(32, 32);
    this.imgMeal.src = `${window.location.origin}/assets/images/chart/meal.svg`;
    this.imgRest = new Image(32, 32);
    this.imgRest.src = `${window.location.origin}/assets/images/chart/rest.svg`;
    this.imgTrainning = new Image(32, 32);
    this.imgTrainning.src = `${window.location.origin}/assets/images/chart/training.svg`;
    this.imgCustom = new Image(32, 32);
    this.imgCustom.src = `${window.location.origin}/assets/images/chart/custom.svg`;
    this.imgRestEnd = new Image(32, 32);
    this.imgRestEnd.src = `${window.location.origin}/assets/images/chart/rest-end.svg`;
    this.imgTrainningEnd = new Image(32, 32);
    this.imgTrainningEnd.src = `${window.location.origin}/assets/images/chart/training-end.svg`;
  }

  getEventImage(type: EventType, end = false) {
    switch (type) {
      case EventType.CUSTOM:
        return this.imgCustom;
      case EventType.FEELING:
        return this.imgFeeling;
      case EventType.MEAL:
        return this.imgMeal;
      case EventType.REST:
        return end ? this.imgRestEnd : this.imgRest;
      case EventType.TRAINING:
        return end ? this.imgTrainningEnd : this.imgTrainning;
    }
  }

  dateInTimezone(date: string, timezone: string | undefined) {
    return timezone ? moment(dateFormat.utcToTimezone(date, timezone)).valueOf() : moment.utc(date).valueOf();
  }

  getSerieConfig(type: SerieName) {
    switch (type) {
      case SerieName.Median:
        return {
          fill: undefined,
          backgroundColor: '#8459C0',
        };
      case SerieName.Open:
        return {
          fill: 2,
          backgroundColor: '#DFCAFB',
        };
      case SerieName.Close:
        return {
          fill: -1,
          backgroundColor: '#DFCAFB',
        };
      case SerieName.High:
        return {
          fill: 4,
          backgroundColor: '#EEE3FD',
        };
      case SerieName.Low:
        return {
          fill: -1,
          backgroundColor: '#EEE3FD',
        };
    }
  }

  generateEventLabel = ({
    context,
    event,
    date,
    maxValue,
    clickable,
    endLabel = false,
  }: {
    context: any;
    event: EventScoreDto;
    date: number;
    maxValue: number;
    clickable: boolean;
    endLabel?: boolean;
  }) => {
    const labelFunction = (c: any) => {
      if (clickable) {
        context.emit('annotation-event-click', c.id as string);
      }
    };
    const endLabelFunction = (c: any) => {
      if (clickable) {
        let id = c.id as string;
        if (id.includes('-end')) {
          id = id.replace('-end', '');
        }
        context.emit('annotation-event-click', id);
      }
    };
    return {
      type: 'label',
      xValue: date,
      yValue: maxValue < 180 ? 180 : maxValue - 10,
      content: chartHelper.getEventImage(event.type, endLabel),
      width: 32,
      height: 32,
      click: endLabel ? endLabelFunction : labelFunction,
    };
  };

  getEventsAnnotation({
    context,
    maxValue,
    clickable = true,
    events,
    timezone,
  }: {
    context: any;
    maxValue: number;
    clickable?: boolean;
    events?: EventScoreDto[];
    timezone?: string;
  }) {
    if (!events) {
      return {};
    }
    const annotations: any = {};

    events.forEach((e) => {
      const eventStartDate = this.dateInTimezone(e.date, timezone);
      const label = this.generateEventLabel({ context, event: e, date: eventStartDate, maxValue, clickable });
      const line = {
        type: 'line',
        value: eventStartDate,
        scaleID: 'x',
        borderDash: [4, 4],
        borderWidth: 0.5,
      };

      annotations[e.id + '-line'] = line;
      annotations[e.id] = label;

      if (e.endDate) {
        const eventEndDate = this.dateInTimezone(e.endDate, timezone);
        const labelEnd = this.generateEventLabel({
          context,
          event: e,
          date: eventEndDate,
          maxValue,
          clickable,
          endLabel: true,
        });
        const lineEnd = {
          type: 'line',
          value: eventEndDate,
          scaleID: 'x',
          borderDash: [4, 4],
          borderWidth: 0.5,
        };

        annotations[e.id + '-line-end'] = lineEnd;
        annotations[e.id + '-end'] = labelEnd;
      }
    });
    return {
      events: ['click'],
      annotations: annotations,
    };
  }

  generateEventLabelWearables = ({
    context,
    event,
    date,
    maxValue,
    clickable,
    endLabel = false,
  }: {
    context: any;
    event: EventScoreDto;
    date: number;
    maxValue: number;
    clickable: boolean;
    endLabel?: boolean;
  }) => {
    const labelFunction = (c: any) => {
      if (clickable) {
        context.emit('annotation-event-click', c.id as string);
      }
    };
    const endLabelFunction = (c: any) => {
      if (clickable) {
        let id = c.id as string;
        if (id.includes('-end')) {
          id = id.replace('-end', '');
        }
        context.emit('annotation-event-click', id);
      }
    };
    return {
      type: 'label',
      xValue: date,
      yValue: maxValue < 180 ? 180 : maxValue - 10,
      content: chartHelper.getEventImage(event.type, endLabel),
      width: 22,
      height: 22,
      click: endLabel ? endLabelFunction : labelFunction,
    };
  };

  getEventsAnnotationWearables({
    context,
    maxValue,
    clickable = true,
    events,
    timezone,
  }: {
    context: any;
    maxValue: number;
    clickable?: boolean;
    events?: EventScoreDto[];
    timezone?: string;
  }) {
    if (!events) {
      return {};
    }
    const annotations: any = {};

    events.forEach((e) => {
      const eventStartDate = this.dateInTimezone(e.date, timezone);
      const label = this.generateEventLabelWearables({ context, event: e, date: eventStartDate, maxValue, clickable });
      const line = {
        type: 'line',
        value: eventStartDate,
        scaleID: 'x',
        borderDash: [4, 4],
        borderWidth: 0.5,
      };

      annotations[e.id + '-line'] = line;
      annotations[e.id] = label;

      if (e.endDate) {
        const eventEndDate = this.dateInTimezone(e.endDate, timezone);
        const labelEnd = this.generateEventLabelWearables({
          context,
          event: e,
          date: eventEndDate,
          maxValue,
          clickable,
          endLabel: true,
        });
        const lineEnd = {
          type: 'line',
          value: eventEndDate,
          scaleID: 'x',
          borderDash: [4, 4],
          borderWidth: 0.5,
        };

        annotations[e.id + '-line-end'] = lineEnd;
        annotations[e.id + '-end'] = labelEnd;
      }
    });
    return {
      events: ['click'],
      annotations: annotations,
    };
  }

  calculateGradient = (graphId: string, graphHeight: number) => {
    const container = document.getElementById(graphId);
    if (!container) {
      return 'rgba(162, 120, 219, 0.3)';
    }

    const canvas = container as HTMLCanvasElement;
    if (!canvas) {
      return 'rgba(162, 120, 219, 0.3)';
    }
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return 'rgba(162, 120, 219, 0.3)';
    }
    try {
      const gradient = ctx?.createLinearGradient(0, 0, 0, graphHeight);
      gradient.addColorStop(0, 'rgba(132, 89, 192, 0.8)');
      gradient.addColorStop(1, 'rgba(162, 120, 219,0)');
      return gradient;
    } catch {
      return 'rgba(162, 120, 219, 0.3)';
    }
  };
}

export const chartHelper = new ChartHelper();
